import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FormioReactComponent } from "@formio/react";
import editForm from "./Comment.form";
import { createRoot } from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { ConsoleError, ConsoleInfo, ConsoleWarn } from "../../Common/Logger";
import store, { RootState } from "../../Redux/store";
import officerLogo from "../../Images/officer.png";
import userLogo from "../../Images/user-profile.png";
import moment from "moment";
import DOMPurify from "dompurify";
import LoginHelper from "../../Common/loginHelper";
import {
	addNewApplicantCommentAsync,
	deleteApplicantCommentAsync,
	editApplicantCommentAsync,
	getCommentsByFieldNameAsync,
} from "../../Redux/Comment/ApplicantCommentSlice";
import {
	ApplicantComment,
	checkExternalCommentNotification,
	COMMENT_UI_CONFIG,
	CommentMadeBy,
	CommentStatus,
	formatFieldName,
	getSubmissionId,
	getUserFullName,
} from "./CommentUtils";
import "./Comment.css";
import { CommentTextArea } from "./CommentTextArea";
import { CommentTextField } from "./CommentTextField";

// Formio Custom React Component
export default class ApplicantCommentComponent extends FormioReactComponent {
	private container: any;

	/**
	 * This function tells the form builder about your component. It's name, icon and what group it should be in.
	 *
	 * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
	 */
	static builderInfo = {
		title: "Applicant Comment",
		group: "basic",
		icon: "fa fa-table",
		weight: 70,
		documentation: "/userguide/#table",
		schema: ApplicantCommentComponent.schema(),
	};

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema() {
		return (FormioReactComponent as any).schema({
			type: "applicantcomment",
			numRows: 2,
			numCols: 1,
		});
	}

	/*
	 * Defines the settingsForm when editing a component in the builder.
	 */
	public static editForm = editForm;

	/**
	 * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
	 *
	 * @param DOMElement
	 * #returns ReactInstance
	 */
	attachReact(element: any) {
		this.hideLabelElement();

		ConsoleInfo(
			"attaching react component to formio key",
			(this as any).component.key,
		);

		this.container = createRoot(element);
		return this.container.render(
			<Provider store={store}>
				<ApplicantCommentModal
					id={(this as any).id}
					parentLabel={(this as any).component.label}
				/>
			</Provider>,
		);
	}

	private hideLabelElement() {
		const labelElement = document.getElementById(
			`l-${(this as any).id}-${(this as any).key}`,
		);
		if (labelElement) {
			labelElement!.style.display = "none";
		}
		return labelElement;
	}

	/**
	 * Automatically detach any react components.
	 *
	 * @param element
	 */
	detachReact(element: any) {
		if (element) {
			super.detachReact(element);
		}
	}

	verify(token: string): boolean {
		return true;
	}
}

// React Functional Component
interface Props {
	id: string;
	parentLabel: string;
}
const ApplicantCommentModal: React.FC<Props> = ({ id, parentLabel }) => {
	ConsoleInfo("ApplicantCommentModal rendering:", parentLabel);
	const [isSubmissionReturned, setIsSubmissionReturned] =
		useState<boolean>(false);
	const [notificationIcon, setNotificationIcon] = useState<boolean>(false);

	const fieldName = formatFieldName(parentLabel);
	const commentsOfThisField: ApplicantComment[] = useSelector(
		(state: RootState) =>
			state.ApplicantComment.ApplicantComments[fieldName] ?? [],
	);

	const [modalOpen, setModalOpen] = useState(false);
	const commentSectionRef = useRef<HTMLUListElement>(null);
	const [newCommentText, setNewCommentText] = useState("");
	const [editCommentId, setEditCommentId] = useState<string | undefined>(
		undefined,
	);
	const [deleteCommentId, setDeleteCommentId] = useState<string | undefined>(
		undefined,
	);

	const submissionId = getSubmissionId();

	useEffect(() => {
		const sessionData: any = sessionStorage.getItem("info");
		const isRouteBack = sessionData as string;
		setIsSubmissionReturned(isRouteBack?.includes("Returned"));
	}, []);

	useEffect(() => {
		store.dispatch(
			getCommentsByFieldNameAsync({
				fieldName,
				submissionId,
			}),
		);
	}, [fieldName, submissionId]);

	useEffect(() => {
		const enableIcon = checkExternalCommentNotification(
			commentsOfThisField,
			submissionId,
			fieldName,
			isSubmissionReturned,
		);
		setNotificationIcon(enableIcon);
	}, [commentsOfThisField, fieldName, isSubmissionReturned, submissionId]);

	const openModalClick = () => {
		setModalOpen(true);
		if (commentSectionRef.current) {
			commentSectionRef.current.scrollTop = 1000;
		}
	};

	const closeModalClick = () => {
		cancelAction();
		setModalOpen(false);
	};

	const saveModalClick = () => {
		if (newCommentText.trim() === "") {
			ConsoleWarn("No text inserted for comment");
			return;
		}

		if (!submissionId) {
			ConsoleError("Unable to submit comment");
			throw new Error("Unable to submit comment");
		}

		const user = LoginHelper.getLoginUser();
		const username = getUserFullName(user);

		const body: ApplicantComment = {
			Comment: DOMPurify.sanitize(newCommentText),
			OfficerId: "",
			AuthorOfficerId: null,
			ApplicantId: username,
			AuthorApplicantId: user.Id,
			FieldName: fieldName,
			SubmissionId: submissionId,
			Timestamp: new Date(),
			Status: CommentStatus.DRAFT,
			IsCurrent: 1,
		};

		if (!isSubmissionReturned) {
			ConsoleWarn("You cannot add comment to officer right now.");
			alert("You cannot add comment to officer right now.");
			cancelAction();
			return;
		}

		store.dispatch(addNewApplicantCommentAsync(body)).then(() => {
			store.dispatch(
				getCommentsByFieldNameAsync({
					fieldName,
					submissionId,
				}),
			);
			cancelAction();
		});

		if (commentSectionRef.current) {
			commentSectionRef.current.scrollTop = 1000;
		}
	};

	const editCommentClick = (id: string, oldCommentText: string) => {
		setEditCommentId(id);
		setNewCommentText(oldCommentText);
	};

	const deleteCommentClick = (id: string) => {
		store.dispatch(deleteApplicantCommentAsync(id)).then(() => {
			store.dispatch(
				getCommentsByFieldNameAsync({
					fieldName,
					submissionId,
				}),
			);
			cancelAction();
		});
	};

	const saveEditModalClick = () => {
		if (newCommentText.trim() === "") {
			ConsoleWarn("No text inserted for comment");
			return;
		}

		if (!submissionId) {
			ConsoleError("Unable to submit comment");
			throw new Error("Unable to submit comment");
		}

		const user = LoginHelper.getLoginUser();
		const username = getUserFullName(user);

		const body: ApplicantComment = {
			Comment: DOMPurify.sanitize(newCommentText),
			OfficerId: "",
			AuthorOfficerId: null,
			ApplicantId: username,
			AuthorApplicantId: user.Id,
			FieldName: fieldName,
			SubmissionId: submissionId,
			Timestamp: new Date(),
			Status: CommentStatus.DRAFT,
			IsCurrent: 1,
		};

		if (!isSubmissionReturned) {
			ConsoleWarn("You cannot add comment to officer right now.");
			alert("You cannot add comment to officer right now.");
			cancelAction();
			return;
		}

		store
			.dispatch(editApplicantCommentAsync({ id: editCommentId!, body: body }))
			.then(() => {
				store.dispatch(
					getCommentsByFieldNameAsync({
						fieldName,
						submissionId,
					}),
				);
				cancelAction();
			});

		if (commentSectionRef.current) {
			commentSectionRef.current.scrollTop = 1000;
		}
	};

	const cancelAction = () => {
		setEditCommentId(undefined);
		setDeleteCommentId(undefined);
		setNewCommentText("");
	};

	const onTextChange = (e: string) => {
		setNewCommentText(e);
	};

	return (
		<>
			{!modalOpen && (
				<button
					className="btn"
					data-target={id}
					data-clickable={true}
					id={parentLabel}
					onClick={openModalClick}>
					{notificationIcon ? (
						<div className="notification">
							<i
								className="message-icon"
								aria-hidden="true"></i>
							<span className="badge">i</span>
						</div>
					) : (
						<i
							className="message-icon"
							aria-hidden="true"></i>
					)}
				</button>
			)}
			{modalOpen && (
				<div
					data-modal-id={id}
					className="comment-container"
					style={{ display: "flex" }}>
					<div className="comment-modal">
						<div className="comment-header_container">
							<h4
								id="applicant-comment-header"
								className="comment-header">
								Comments to / from Officer
							</h4>
							<i
								className="fa fa-times comment-close"
								data-close={id}
								onClick={closeModalClick}></i>
						</div>
						<ul
							id="comment-ul"
							className="comment-ul"
							data-preview={id}
							ref={commentSectionRef}>
							{commentsOfThisField
								?.filter(
									(cm) =>
										cm.SubmissionId === submissionId &&
										cm.FieldName === fieldName,
								)
								?.map((cm) => {
									const belongsTo = CommentMadeBy(cm);
									const displayLogo =
										belongsTo === "officer" ? officerLogo : userLogo; // default is userLogo
									const displayAuthorName =
										belongsTo === "applicant"
											? cm.ApplicantId
											: belongsTo === "officer"
												? cm.OfficerId
												: "input username is empty!";
									const displayAuthorType =
										belongsTo === "applicant"
											? "Applicant"
											: belongsTo === "officer"
												? "Reviewing Officer"
												: undefined;
									const commentDate = moment(cm.Timestamp);

									const allowModify =
										isSubmissionReturned && cm.IsCurrent === 1;
									return (
										<li>
											<img
												style={{
													height: "40px",
													width: "40px",
													verticalAlign: "top",
												}}
												src={displayLogo}
												alt=""></img>
											<div className="comment-user_details">
												<div>{displayAuthorName}</div>
												<small className="comment-small">
													{displayAuthorType
														? `${displayAuthorType} . ${commentDate.format(
																"DD-MMM-YYYY HH:mm A",
															)}`
														: commentDate.format("DD-MMM-YYYY HH:mm A")}
												</small>
											</div>
											<CommentTextField commentText={cm.Comment} />
											{allowModify && (
												<>
													<button
														id={`edit-link-${cm._id}`}
														className="comment-link-button"
														disabled={!!editCommentId || !!deleteCommentId}
														onClick={() => {
															editCommentClick(cm._id!, cm.Comment);
														}}>
														edit
													</button>
													<button
														id={`delete-link-${cm._id}`}
														className="comment-link-button"
														disabled={!!editCommentId || !!deleteCommentId}
														onClick={() => {
															setDeleteCommentId(cm._id!);
														}}>
														delete
													</button>
												</>
											)}
											<hr></hr>
										</li>
									);
								})}
						</ul>
						{!deleteCommentId && (
							<CommentTextArea
								id={`external-textarea-${id}`}
								dataId={id}
								limit={COMMENT_UI_CONFIG.COMMENT_TEXT_AREA_CHARACTER_LIMIT}
								disabled={!isSubmissionReturned}
								handleChange={onTextChange}
								value={newCommentText}
								rows={5}
								placeholder="Please enter a comment"
							/>
						)}
						{deleteCommentId && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									height: "5em",
								}}>
								<h6>Do you wish to delete this comment?</h6>
							</div>
						)}
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<div
								style={{
									display: "flex",
								}}>
								{!editCommentId && !deleteCommentId && (
									<button
										id={`external-save-btn-${id}`}
										data-save={id}
										className="btn btn-primary comment-save_and_close"
										disabled={!isSubmissionReturned}
										onClick={saveModalClick}
										type="button">
										Save
									</button>
								)}
								{editCommentId && (
									<button
										id={`external-save-btn-${id}`}
										data-save={id}
										className="btn btn-primary comment-save_and_close"
										onClick={saveEditModalClick}
										disabled={!isSubmissionReturned}
										type="button">
										Update
									</button>
								)}
								{deleteCommentId && (
									<button
										id={`external-save-btn-${id}`}
										data-save={id}
										className={"btn btn-primary comment-save_and_close"}
										onClick={() => {
											deleteCommentClick(deleteCommentId);
											cancelAction();
										}}
										disabled={!isSubmissionReturned}
										type="button">
										Delete
									</button>
								)}
							</div>
							<div
								style={{
									display: "flex",
								}}>
								{(editCommentId || deleteCommentId) && (
									<button
										id={`external-save-btn-${id}`}
										data-save={id}
										className="btn btn-primary btn-md btn-block cancelbtn comment-save_and_close"
										onClick={() => {
											cancelAction();
										}}>
										Cancel
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
