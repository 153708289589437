interface Props {
	commentText: string;
}
export const CommentTextField: React.FC<Props> = ({ commentText }) => {
	return (
		<p
			style={{
				wordWrap: "break-word",
				whiteSpace: "break-spaces",
				marginTop: "12px",
			}}>
			{commentText}
		</p>
	);
};
