import Formio from "formiojs/Formio";
import $ from "jquery";
import { ConsoleError, ConsoleInfo } from "./Logger";

const sanitizeOptions = {
	allowedTags: [],
	allowedAttrs: [],
};

export function sanitizeObject(obj) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (typeof obj[key] === "object" && obj[key] !== null) {
				obj[key] = sanitizeObject(obj[key]);
			} else if (typeof obj[key] === "string") {
				obj[key] = Formio.Utils.sanitize(obj[key], sanitizeOptions);
			}
		}
	}
	return obj;
}

export function TablePaginationWithSelectBoxAndSelectIcon() {
	return {
		"& .MuiInputBase-root": {
			minWidth: "unset",
		},
		"& .MuiSelect-select": {
			border: "1px solid #EAECF0",
			borderRadius: "2px",
			marginTop: "-15px",
		},
		"& .MuiSelect-icon": {
			marginTop: "-8px",
		},
		"& .MuiTablePagination-actions": {
			display: "none",
		},
		"& .MuiTablePagination-displayedRows::before": {
			content: '"Showing "',
		},
	};
}

export function DirectUserToIdpWebsite() {
	const newWindow = window.open(
		"https://www.mpa.gov.sg/maritime-singapore/what-maritime-singapore-offers/programmes-to-support-your-maritime-business/industry-digital-plan",
		"_blank",
		"noopener,noreferrer",
	);
	if (newWindow) newWindow.opener = null;
}

/**
 * Wait for document to be ready, then execute the callback function after 90 seconds
 * @param {() => void} callback
 */
export function ScheduleCallbackAfterDocumentReadyWithDelay(callback) {
	ConsoleInfo("ExecuteCallbackAfterDocumentReadyWithDelay: start");

	$(() => {
		setTimeout(callback, 90);
	});

	ConsoleInfo("ExecuteCallbackAfterDocumentReadyWithDelay: end");
}

function mutateDateToSGT(localDateString) {
  // Validate the input
  if (typeof localDateString !== 'string' || localDateString.trim() === '') {
    return localDateString; // Return unchanged if invalid
  }
  // Check and remove '.000Z' if present
  if (localDateString.endsWith('.000Z')) {
    localDateString = localDateString.slice(0, -5); // Remove the last 5 characters
  }
	
  // Replace time and offset, ensuring the result is a valid ISO 8601 string
  const mutatedDateString = localDateString.replace(
    /T\d{2}:\d{2}:\d{2}([+-]\d{2}:\d{2})?/,
    "T00:00:00+08:00"
  );

  // Ensure the final string is valid
  if (isNaN(new Date(mutatedDateString).getTime())) {
    throw new Error(`Invalid date format produced: ${mutatedDateString}`);
  }

  return mutatedDateString;
}

function convertToTargetTimeZone (dateString, targetOffset) {
  const date = new Date(dateString);

  // Parse the target offset (e.g., "+08:00")
  const [offsetSign, offsetHours, offsetMinutes] = targetOffset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
  const totalOffsetMinutes =
    (parseInt(offsetHours, 10) * 60 + parseInt(offsetMinutes, 10)) * (offsetSign === "+" ? 1 : -1);

  // Adjust the date by the target offset
  const adjustedDate = new Date(date.getTime() + totalOffsetMinutes * 60 * 1000);

  // Format the date components manually
  const year = adjustedDate.getUTCFullYear();
  const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(adjustedDate.getUTCDate()).padStart(2, '0');
  const hours = String(adjustedDate.getUTCHours()).padStart(2, '0');
  const minutes = String(adjustedDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(adjustedDate.getUTCSeconds()).padStart(2, '0');

  // Return the formatted date string with the target offset
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${targetOffset}`;
};

function getUserLocalTimezoneOffset() {
	// Get the current timezone offset in minutes
	let timezoneOffsetMinutes = new Date().getTimezoneOffset();

	// Convert the timezone offset to hours and minutes
	let offsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
	let offsetMinutes = Math.abs(timezoneOffsetMinutes) % 60;

	// Determine the sign of the offset
	let offsetSign = timezoneOffsetMinutes >= 0 ? "-" : "+";

	// Format the offset as +HH:MM or -HH:MM
	let formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

  return formattedOffset;
}

function updateToUserLocalTimezone(dateString) {
	// Replace the timezone part of the date string
	return dateString.replace(/\+.*$/, getUserLocalTimezoneOffset());
}

function convertDateStringSGTToUserLocal(sgUTCDateString) {
	const sgLocalDateString = convertToTargetTimeZone(sgUTCDateString, "+08:00");
	const userLocalDateString = updateToUserLocalTimezone(sgLocalDateString);

	return userLocalDateString;
}

function convertDateStringOverseaLocalToUserLocal (overseaDateString) {
	// Get the local timezone offset in minutes (e.g., -480 for UTC-8)
	const localOffset = new Date().getTimezoneOffset();

	// Convert the offset to hours and minutes, and format it as +hh:mm or -hh:mm
	const sign = localOffset > 0 ? "-" : "+"; // Check whether it's ahead or behind UTC
	const hours = String(Math.abs(localOffset) / 60).padStart(2, "0");
	const minutes = String(Math.abs(localOffset) % 60).padStart(2, "0");
	const timezoneOffset = `${sign}${hours}:${minutes}`;
	
	// Replace the time part with midnight and append the local timezone offset
	const updatedResult = overseaDateString.replace(/T.*$/, "") + "T00:00:00" + timezoneOffset;

	if (overseaDateString === updatedResult) {
		return overseaDateString;
	}
	
	return updatedResult;
}

export function convertDateUTCToSGT (localDateString) {
  // Validate the input
  if (typeof localDateString !== 'string' || localDateString.trim() === '' || localDateString?.length === 0) {
    return localDateString; // Return the input unchanged if it's not valid
  }

  try {
    // Replace the offset and ensure valid format
    const convertedLocalSGT = mutateDateToSGT(localDateString); // Change offset to +08:00

    // Parse the updated string into a Date object
    const dateWithNewOffset = new Date(convertedLocalSGT);

    // Convert to UTC time
    const utcTime = dateWithNewOffset.toISOString();

    return utcTime;
  } catch (error) {
    ConsoleError("Error processing date:", error);
  }
}

export function helperTimezoneDateCorrection (localDateString) {
	if (!localDateString?.length > 0) {
		return localDateString;
	}
	
	// Handle Draft->Loaded from main form scenario timezone correction (Applicable to both sub-forms on loading data from main form only)
	if (localDateString?.endsWith("Z")) {
		return convertDateStringSGTToUserLocal(localDateString);
	}
	// Handle Draft->New scenario timezone correction (Applicable to main form only)
	else {
		return convertDateStringOverseaLocalToUserLocal(localDateString);
	};
}
